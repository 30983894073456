import React from "react";
import sections from "./AllSections";
import { useSelector } from "react-redux";

const BodyPart = () => {
  const { user, is_connected } = useSelector((state) => state.UserReducer);

  return (
    <div className="w-full border border-border rounded-b-[2vw]">
      <div className="w-[87%] mx-auto">
        {is_connected && <Sections user={user} />}
      </div>
    </div>
  );
};

const Sections = ({ user }) => {
  //   const MySections = sections[user.type];
  const MySections = sections[user.type];
  const [activeSection, setActiveSection] = React.useState(0);

  return (
    <div className="w-full">
      <div className="w-full mt-[10vw] flex items-center justify-between border-b-[3px] border-borderGray">
        {MySections.map((section, index) => {
          let isCurrent = activeSection === index;
          let currentClass = isCurrent
            ? "!text-black border-b-[3px] !border-black [text-shadow:0px_0px_1px_black]"
            : "";

          return (
            <div
              key={index}
              className={`
                text-center translate-y-[3px] text-[1.3vw] transition-all duration-100 hover:text-black
                px-[1vw] pb-[0.7vw] text-textGray cursor-pointer font-normal border-b-[3px] border-transparent
                ${currentClass}`}
              onClick={() => setActiveSection(index)}
            >
              {section.name}
            </div>
          );
        })}
      </div>
      <div className="mt-[2vw]  pb-[3vw]">
        {MySections[activeSection].Component}
      </div>
    </div>
  );
};

export default BodyPart;
