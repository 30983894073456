import React, { useEffect } from "react";
import Select from "./Select";

const GoogleTranslate = ({
  isUp = false,
  shorted = false,
  noshebron = false,
}) => {
  useEffect(() => {
    loadGoogleTranslate(); // Load the Google Translate function when the component mounts
  }, []);

  const loadGoogleTranslate = () => {
    if (window.google && window.google.translate) {
      return; // Skip loading if already loaded
    }

    const script = document.createElement("script");
    script.src =
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    document.body.appendChild(script);

    // Google Translate initialization function
    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: "en", // Default page language
          includedLanguages: "en,fr,ar", // Languages to include
        },
        "google_translate_element"
      );
    };
  };

  useEffect(() => {
    setTimeout(() => {
      const selectElement = document.querySelector(
        "#google_translate_element select"
      );
      if (selectElement) {
        // selectElement.value = "en";
        // selectElement.dispatchEvent(new Event("change"));
      }
    }, 4000);
  }, []);

  const handleLanguageChange = (value) => {
    // alert("value : " + value);
    const language = value;

    // Update the Google Translate dropdown selection
    const selectElement = document.querySelector(
      "#google_translate_element select"
    );
    if (selectElement) {
      selectElement.value = language;
      selectElement.dispatchEvent(new Event("change")); // Trigger change event to update the translation

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  return (
    <div>
      <div id="google_translate_element"></div>
      <Select
        handleLanguageChange={handleLanguageChange}
        isUp={isUp}
        shorted={shorted}
        noshebron={noshebron}
      />
    </div>
  );
};

// function GoogleTranslate() {
//   return <div></div>;
// }

export default GoogleTranslate;
