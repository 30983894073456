import useRefrechUser from "Hooks/useRefrechUser";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useUser } from "Store/hooks/useUser";
import customAxios from "Utils/axios/axios";

function UploadProfileImage({ imgType = "MainImg", className = "" }) {
  const { user } = useUser();
  const { refrechUser } = useRefrechUser();

  const [Image, setImage] = useState({
    url: "",
    uploading: false,
    done: false,
  });
  const inputRef = useRef(null);

  useEffect(() => {
    console.log(Image);
    if (Image.done) {
      //   add_img(Image.url);
      UpdateImage();
    }
  }, [Image]);

  const handleClick = () => {
    if (Image.uploading) return;
    inputRef.current.click();
  };

  const changeImg = (event) => {
    let file = event.target.files[0];
    UploadImg(file, Image, setImage);
  };

  const UpdateImage = () => {
    const roles = {
      USER: "user",
      COACH: "coache",
      NUTRITIONIST: "nutritionist",
    };

    customAxios
      .put(`/api/${roles[user.type]}/update/${user._id}`, {
        [imgType]: Image.url,
      })
      .then((res) => {
        toast.success("Updated successfully");
        refrechUser();
        setImage({ url: "", uploading: false, done: false });
      })
      .catch((err) => {
        toast.error("Error updating Image");
      });
  };

  return (
    <>
      <img
        onClick={handleClick}
        src="/svgs/upload_img.svg"
        alt="upload"
        className={className}
      />
      <input
        ref={inputRef}
        type="file"
        className="hidden"
        onChange={changeImg}
      />
    </>
  );
}

const UploadImg = async (file, Image, SetImage) => {
  // Initial File before upload
  const formData = new FormData();
  formData.append("image", file);
  SetImage({ ...Image, uploading: true, done: false });

  // the axios upload
  return await customAxios
    .post("/api/upload/images/single", formData)
    .then((response) => {
      SetImage({
        ...Image,
        url: response.data.file,
        done: true,
        uploading: false,
      });
    })
    .catch((error) => {
      toast.error("Error Uploading Image");
    });
};

const UpdateProfileImg = (img, type, user_id, refrechUser) => {};

export default UploadProfileImage;
