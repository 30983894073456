import { useEffect, useState } from "react";
import { useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView } from "@blocknote/mantine";
import "@blocknote/core/fonts/inter.css";
import "@blocknote/mantine/style.css";
import axios from "../../Utils/axios/axios";
import { handleTextToSpeech } from "./extractText";

function NotionEditor({
  label = "",
  name = "",
  value = "",
  editable = true,
  onChange = () => {},
  clasName = "",
  isUpdate = false,
  play = false,
  canPlay = false,
}) {
  let backend_url = "https://backend.trena.pro/api";
  const Upload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("image", file);
      const resp = await axios.post("/api/upload/images/single", formData);
      return backend_url + resp.data.file;
    } catch (err) {
      console.log(err);
      return "";
    }
  };

  const [setted, setSetted] = useState(false);
  const editor = useCreateBlockNote({
    uploadFile: Upload,
  });

  useEffect(() => {
    if (value && value !== "" && !setted && editor && isUpdate) {
      let bloks = JSON.parse(value);
      editor.insertBlocks(bloks, editor.document[0].id, "before");
      // remove the last block

      editor.removeBlocks([editor.document[editor.document.length - 1].id]);

      setSetted(true);
    }
  }, [setted, value, editor]);

  useEffect(() => {
    if (canPlay) {
      handleTextToSpeech(value);

      if (play) {
        window.speechSynthesis.resume();
      } else {
        window.speechSynthesis.pause();
      }
    }
  }, [play, canPlay, value]);

  const onLocalChange = () => {
    let bloks = [];
    editor.forEachBlock((block) => {
      bloks.push(block);
      return true;
    });

    onChange({
      target: {
        name,
        value: JSON.stringify(bloks || []),
      },
    });
  };

  return (
    <div className={`mb-2 ${clasName} ${editable ? "" : "only-show-notion"}`}>
      <div>
        {label && <label className="form-label">{label}</label>}
        <BlockNoteView
          editor={editor}
          editable={editable}
          onChange={onLocalChange}
          theme="light"
        />
      </div>
    </div>
  );
}

export default NotionEditor;
