const extractText = (value) => {
  if (!value) return "";

  const parsedContent = JSON.parse(value);

  let text = "";

  parsedContent.forEach((block) => {
    text += extractTextFromBlock(block) + " ";
  });

  return text.trim();
};

const extractTextFromBlock = (block) => {
  let blockText = "";

  if (block.content && Array.isArray(block.content)) {
    block.content.forEach((item) => {
      if (item.type === "text" && item.text) {
        blockText += item.text + " ";
      } else if (item.children && item.children.length > 0) {
        blockText += extractTextFromBlock(item) + " ";
      }
    });
  }

  return blockText.trim();
};

export const handleTextToSpeech = (value) => {
  const text = extractText(value);
  if (!text) {
    alert("No text found to read!");
    return;
  }

  const speech = new SpeechSynthesisUtterance(text);

  window.speechSynthesis.onvoiceschanged = function () {
    window.speechSynthesis.getVoices();
    let voice = window.speechSynthesis.getVoices()[3];
    if (voice) {
      speech.voice = voice;
    } else {
      speech.voice = window.speechSynthesis.getVoices()[0];
    }

    speech.pitch = 1.2;
    speech.rate = 1;
    window.speechSynthesis.speak(speech);
  };
};
