import React, { useEffect } from "react";
import { useUser } from "Store/hooks/useUser";

function Logout() {
  const { logout } = useUser();

  useEffect(() => {
    logout();
  }, []);

  return <> </>;
}

export default Logout;
