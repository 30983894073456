export const items = {
  COACH: [
    {
      icon: "/svgs/navbarProfileMenu/person.svg",
      text: "My Profile",
      link: "/dashboard/profile",
    },
    {
      icon: "/svgs/navbarProfileMenu/workout.svg",
      text: "Workout program",
      link: "/dashboard/workout",
    },
    {
      icon: "/svgs/navbarProfileMenu/articles.svg",
      text: "My Articles",
      link: "/dashboard/articles",
    },
    {
      icon: "/svgs/navbarProfileMenu/settings.svg",
      text: "Settings",
      link: "/dashboard/my-settings",
    },
    {
      icon: "/svgs/navbarProfileMenu/logout.svg",
      text: "Logout",
      link: "/dashboard/logout",
    },
  ],
  USER: [
    {
      icon: "/svgs/navbarProfileMenu/person.svg",
      text: "My Profile",
      link: "/dashboard/profile",
    },
    {
      icon: "/svgs/navbarProfileMenu/workout.svg",
      text: "Workout program",
      link: "/dashboard/workout",
    },
    {
      icon: "/svgs/navbarProfileMenu/articles.svg",
      text: "My Articles",
      link: "/dashboard/articles",
    },
    {
      icon: "/svgs/navbarProfileMenu/settings.svg",
      text: "Settings",
      link: "/dashboard/my-settings",
    },
    {
      icon: "/svgs/navbarProfileMenu/logout.svg",
      text: "Logout",
      link: "/dashboard/logout",
    },
  ],
  NUTRITIONIST: [
    {
      icon: "/svgs/navbarProfileMenu/person.svg",
      text: "My Profile",
      link: "/dashboard/profile",
    },
    {
      icon: "/svgs/navbarProfileMenu/workout.svg",
      text: "Workout program",
      link: "/dashboard/workout",
    },
    {
      icon: "/svgs/navbarProfileMenu/articles.svg",
      text: "My Articles",
      link: "/dashboard/articles",
    },
    {
      icon: "/svgs/navbarProfileMenu/settings.svg",
      text: "Settings",
      link: "/dashboard/my-settings",
    },
    {
      icon: "/svgs/navbarProfileMenu/logout.svg",
      text: "Logout",
      link: "/dashboard/logout",
    },
  ],
};
