import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Disconnect } from "Store/user.reducer";

export const useUser = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { user, is_connected, payload } = useSelector(
    (state) => state.UserReducer
  );

  const logout = () => {
    dispatch(Disconnect());
    navigation("/");
  };

  return { user, is_connected, payload, logout };
};
