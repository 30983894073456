import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import ImgBack from "../../Components/Image/ImgBack";
import { ChevronDown } from "lucide-react";
import { items } from "./navbarUserItems";
import { useUser } from "Store/hooks/useUser";
import { Link } from "react-router-dom";
import UseClickOutside from "Hooks/UseClickOutside";

function ConnectedUser() {
  const { is_connected } = useSelector((state) => state.UserReducer);
  const [open, setOpen] = useState(false);
  const { ref } = UseClickOutside(() => {
    setOpen(false);
  });

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!is_connected) {
    return null;
  }

  return (
    <div className="relative w-fit h-fit">
      <div className="flex items-center justify-start space-x-[1vw]">
        <Messages />
        <Notifications />
        <UserAvatar handleOpen={handleOpen} open={open} />
      </div>
      {open && <ProfileMenu closeRef={ref} handleClose={handleClose} />}
    </div>
  );
}

const UserAvatar = ({ handleOpen, open = false }) => {
  const { user } = useSelector((state) => state.UserReducer);

  return (
    <div className="w-fit h-fit relative cursor-pointer" onClick={handleOpen}>
      <ImgBack
        src={user.MainImg}
        alt={user.fullname}
        altsrc="/images/user.png"
        className="w-[3vw] h-[3vw] rounded-full object-cover"
      />
      <div className="h-[1.3vw] w-[1.3vw] flex items-center justify-center -bottom-[0.2vw] -right-[0.2vw] absolute bg-[#3B3B3B] rounded-full">
        <ChevronDown
          strokeWidth={3}
          className={`w-[1vw] translate-y-[0.07vw] text-white ${
            open ? "rotate-180" : ""
          }`}
        />
      </div>
    </div>
  );
};

const Notifications = () => {
  return (
    <div className="w-fit h-fit relative cursor-pointer">
      <img
        src="/svgs/notifs/bill.svg"
        className="w-[3vw] aspect-square"
        alt=""
      />
      <NumberIndex number={0} />
    </div>
  );
};

const Messages = () => {
  return (
    <div className="w-fit h-fit relative cursor-pointer">
      <img
        src="/svgs/notifs/messages.svg"
        className="w-[3vw] aspect-square"
        alt=""
      />
      <NumberIndex number={0} />
    </div>
  );
};

const NumberIndex = ({ number = 0 }) => {
  if (number === 0) {
    return null;
  }

  return (
    <div className="w-[1.2vw] h-[1.2vw] text-[1vw] -top-[0.2vw] -right-[0.2vw] flex items-center justify-center absolute  bg-mainColor text-white  rounded-full">
      <span className="translate-y-[0.1vw]">{number}</span>
    </div>
  );
};

const ProfileMenu = ({ closeRef, handleClose = () => {} }) => {
  const { user } = useUser();
  const links = items[user.type];
  return (
    <div
      ref={closeRef}
      className="w-[14vw] px-[0.8vw] rounded-[0.5vw] no-scroll-bar bg-white absolute top-[120%] right-0 shadow-lg"
    >
      {links.map((item, index) => (
        <ItemLink key={index} linkitem={item} handleClose={handleClose} />
      ))}
    </div>
  );
};

const ItemLink = ({
  linkitem = { link: "", text: "", icon: "" },
  handleClose = () => {},
}) => {
  return (
    <Link
      to={linkitem.link}
      onClick={handleClose}
      className="w-full p-[0.4vw] my-[0.7vw] rounded-[0.4vw] flex items-center justify-start transition-all duration-200 hover:bg-bgGrayDark "
    >
      <img
        src={linkitem.icon}
        alt=""
        className="w-[1.8vw] h-[1.8vw] mr-[0.5vw]"
      />
      <span className="block w-fit text-[1vw] font-[500] break-normal whitespace-nowrap">
        {linkitem.text}
      </span>
    </Link>
  );
};

export default ConnectedUser;
