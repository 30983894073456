import React, { useEffect, useState } from "react";
import styles from "./OneBlog.module.scss";
import Rates from "../../Components/Rates/Rates";

import GoBack from "../../Components/GoBack/GoBack";
import { blogData } from "./data";
import { useParams } from "react-router-dom";
import customAxios from "../../Utils/axios/axios";
import ImgBack from "../../Components/Image/ImgBack";
import NotionEditor from "Components/NotionEditor/NotionEditor";
import { CirclePause, CirclePlay, Pause, Play, Volume2 } from "lucide-react";

function OneBlog() {
  const { id } = useParams();

  const [blog, setBlog] = useState(null);
  const [play, setPlay] = useState(false);
  const [canPlay, setCanPlay] = useState(false);

  useEffect(() => {
    customAxios
      .get(`/api/blogs/get-one/${id}`)
      .then((resp) => {
        console.log(resp.data.data);
        setBlog(resp.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const handlePlaySound = () => {
    setPlay(!play);
    setCanPlay(true);
  };

  if (!blog) {
    return <></>;
  }

  return (
    <div className={styles.main}>
      <div className={styles.head}>
        <GoBack to="/trenapedia" color="black" className={styles.GoBack}>
          Back
        </GoBack>

        <Rates number={5} />
      </div>

      <div className={styles.blog}>
        <h1
          style={{
            marginBottom: "0px",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          {blog.title}

          {!canPlay && (
            <Volume2
              className={styles.icon}
              onClick={handlePlaySound}
              style={{
                marginLeft: "10px",
                cursor: "pointer",
              }}
            />
          )}

          {play && canPlay && (
            <Pause
              className={styles.icon}
              onClick={handlePlaySound}
              style={{
                marginLeft: "10px",
                cursor: "pointer",
              }}
            />
          )}

          {!play && canPlay && (
            <Play
              className={styles.icon}
              onClick={handlePlaySound}
              style={{
                marginLeft: "10px",
                cursor: "pointer",
              }}
            />
          )}
        </h1>

        <div className={styles.content}>
          <NotionEditor
            name="blogContent"
            value={blog.blogContent}
            editable={false}
            isUpdate={true}
            canPlay={canPlay}
            play={play}
          />
        </div>
      </div>
    </div>
  );
}

export default OneBlog;

// voice
